import { Box } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";
import { ReactSVG } from "react-svg";

import { paths } from "@paths";

import logoImg from "images/the_volte_logo_black.svg";

export const LogoLink: React.FC<{}> = ({}) => (
  <Link href={paths.home}>
    <Box w={["7rem", null, null, "9rem"]} cursor="pointer">
      <ReactSVG src={logoImg.src} />
    </Box>
  </Link>
);
